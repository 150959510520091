import React from 'react';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';

// Components
import FormatPrice from '../components/utils/format-price';
import FormatProductTitle from '../components/utils/format-product-title';

const ProductCard = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  & p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h4 {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3,
  h4 {
    font-size: 14px;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 13px;

    margin: 0;
  }
`;

const CollectionsTitle = styled.h2`
  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;
`;

const Search = ({ location }) => {
  if (location.state !== undefined && location.state !== null) {
    const results = location.state.searchResults.map((result, index) => {
      return (
        <Col col={6} md={3} key={result.uid}>
          <LazyLoad height={700}>
            <ProductCard>
              <Link to={'/products/' + result.uid}>
                <img
                  src={result.featuredImage.originalSrc}
                  alt={result.title}
                />
                <h3>{FormatProductTitle(result.title)[0]}</h3>
                <h4>{FormatProductTitle(result.title)[1]}</h4>
                <p>
                  {FormatPrice(
                    result.priceRange.minVariantPrice.amount,
                    result.priceRange.minVariantPrice.currencyCode
                  )}
                </p>
              </Link>
            </ProductCard>
          </LazyLoad>
        </Col>
      );
    });

    return (
      <>
        <Helmet title={`Search Results – Mourne Textiles`} />
        <Container marginTop={'60px'}>
          <Row>
            <Col col={12} lg={6}>
              <Row justifyContent="center" alignItems="center">
                <Col col={12} md={9} lg={10}>
                  <CollectionsTitle>
                    Search results for '{location.state.searchQuery}'
                  </CollectionsTitle>
                </Col>
              </Row>
            </Col>
            {results}
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Helmet title={`Search Results – Mourne Textiles`} />
        <Container marginTop={'60px'}>
          <Row>
            <Col col={12} lg={6}>
              <Row justifyContent="center" alignItems="center">
                <Col col={12} md={9} lg={10}>
                  <CollectionsTitle>Please search for an item</CollectionsTitle>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default Search;
